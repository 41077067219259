import React, { useState, useEffect } from 'react'; 
import Slider from 'react-slick';
import './Home.css'; 
import Contact from '../Contact/Contact';
import Background from '../Images/Background.webp';
import Banquet from '../Images/Banquet.webp';
import Livraison from '../Images/Livraison.webp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils, faChampagneGlasses, faTruckFast, faClipboard } from '@fortawesome/free-solid-svg-icons';
import Menu from '../Menu/Menu';

function Home({ contactRef }) {
    const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 1440);
    const [isSliderActive, setIsSliderActive] = useState(window.innerWidth < 1000);
    const [isEndSliderActive, setIsEndSliderActive] = useState(window.innerWidth < 580);
    const [menuDialogOpen, setMenuDialogOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsScreenSmall(window.innerWidth < 1440);
            setIsSliderActive(window.innerWidth < 1000);
            setIsEndSliderActive(window.innerWidth < 650);
        };

        const debounceResize = debounce(handleResize, 100);

        window.addEventListener('resize', debounceResize);
        return () => {
            window.removeEventListener('resize', debounceResize);
        };
    }, []);

    const openOrderOnline = () => {
        const newWindow = window.open('https://www.foodbooking.com/ordering/restaurant/menu?restaurant_uid=68e0b2de-f6d5-4626-a542-d9e381c34872', '_blank');
        if (!newWindow) alert("Veuillez autoriser les pop-ups pour cette action.");
    };

    const openBanquetsInNewTab = () => {
        const newWindow = window.open('https://www.foodbooking.com/ordering/?restaurant_uid=192ba7bc-da1a-47c8-a944-ca83c35711fc&site_url=aHR0cHM6Ly93d3cuYWxleG90aXF1ZS5jaC8%3D', '_blank');
        if (!newWindow) alert("Veuillez autoriser les pop-ups pour cette action.");
    };

    const CallPhone = () => {
        window.location.href = 'tel:+41217321104';
    };    

    const openReviews = () => {
        const newWindow = window.open('https://www.google.com/search?q=aivs+alexotique.ch&oq=aivs+alexotique.ch&gs_lcrp=EgZjaHJvbWUyCQgAEEUYORigATIHCAEQIRigAdIBCDIyNTlqMGo3qAIAsAIA&sourceid=chrome&ie=UTF-8#lrd=0x478c327cc5697eb5:0x250a9a812a146e6a,1,,,,', '_blank');
        if (!newWindow) alert("Veuillez autoriser les pop-ups pour cette action.");
    };

    const openMenuDialog = () => {
        setMenuDialogOpen(true);
    };

    const closeMenuDialog = () => {
        setMenuDialogOpen(false);
    };

    const sliderSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 650,
                settings: {
                    slidesToShow: 1, 
                }
            }
        ]
    };

    const endSliderSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    const boxes = [
        {
            icon: faChampagneGlasses,
            title: 'Banquet',
            image: Banquet,
            onClick: openBanquetsInNewTab
        },
        {
            icon: faTruckFast,
            title: 'Livraisons',
            image: Livraison,
            onClick: openBanquetsInNewTab
        }
    ];

    const endBoxes = [
        {
            icon: faClipboard,
            title: 'Vos Impressions',
            description: 'Dites-nous ce que vous avez pensé de votre repas.',
            onClick: openReviews 
        },
        {
            icon: faUtensils,
            title: 'Menu du jour',
            description: 'Découvrez nos spécialités fraîches préparées chaque jour.',
            onClick: openMenuDialog 
        }
    ];

    return (
        <div className='background-home'>
            <div className='container-home'>
                <div className='space'></div>
                <div className='background-color'></div>
                <img src={Background} alt="Background" />
                <div className='container-wrapper'>
                    <div className='wrapper'>
                        <div className='border-top'></div>
                        <h4>Bienvenue</h4>
                        <h1>À L'EXOTIQUE</h1>
                        <p>Dégustez les saveurs authentiques de la Thaïlande. Plats raffinés, épices délicates, et un voyage culinaire inoubliable vous attendent.</p>
                        <button onClick={CallPhone}>Appelez-nous</button>
                        <button className='order-online' onClick={openOrderOnline} >Commander en ligne</button>
                        <div className='border-bottom'></div>
                    </div>
                </div>
            </div>
            <div className='container-box'>
                {isScreenSmall ? (
                    <>
                        <div className='container-box-center'>
                            {isSliderActive ? (
                                <Slider {...sliderSettings}>
                                    {boxes.map((box, index) => (
                                        <div className='box' key={index} onClick={box.onClick}>
                                            <div className='container-icon'>
                                                <FontAwesomeIcon icon={box.icon} className='icon' />
                                            </div>
                                            <h3>{box.title}</h3>
                                            <div className='container-img'>
                                                <img src={box.image} alt={box.title} />
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            ) : (
                                <>
                                    {boxes.map((box, index) => (
                                        <div className='box' key={index} onClick={box.onClick}>
                                            <div className='container-icon'>
                                                <FontAwesomeIcon icon={box.icon} className='icon' />
                                            </div>
                                            <h3>{box.title}</h3>
                                            <div className='container-img'>
                                                <img src={box.image} alt={box.title} />
                                            </div>
                                        </div>
                                    ))}
                                </>
                            )}
                        </div>
                        <div className='container-box-end'>
                            {isEndSliderActive ? (
                                <Slider {...endSliderSettings}>
                                    {endBoxes.map((box, index) => (
                                        <div className='box-left-right' key={index} onClick={box.onClick}>
                                            <div className='container-icon'>
                                                <FontAwesomeIcon icon={box.icon} className='icon' />
                                            </div>
                                            <h3>{box.title}</h3>
                                            <p>{box.description}</p>
                                        </div>
                                    ))}
                                </Slider>
                            ) : (
                                <>
                                    <div className='box-left' onClick={openReviews}>
                                        <div className='container-icon'>
                                            <FontAwesomeIcon icon={faClipboard} className='icon' />
                                        </div>
                                        <h3>Vos Impressions</h3>
                                        <p>Dites-nous ce que vous avez pensé de votre repas.</p>
                                    </div>
                                    <div className='box-right' onClick={openMenuDialog}>
                                        <div className='container-icon'>
                                            <FontAwesomeIcon icon={faUtensils} className='icon' />
                                        </div>
                                        <h3>Menu du jour</h3>
                                        <p>Découvrez nos spécialités fraîches préparées chaque jour.</p>
                                    </div>
                                </>
                            )}
                        </div>
                    </>
                ) : (
                    <>
                        <div className='box-left' onClick={openReviews}>
                            <div className='container-icon'>
                                <FontAwesomeIcon icon={faClipboard} className='icon' />
                            </div>
                            <h3>Vos Impressions</h3>
                            <p>Dites-nous ce que vous avez pensé de votre repas.</p>
                        </div>
                        <div className='container-box-center'>
                            {boxes.map((box, index) => (
                                <div className='box' key={index} onClick={box.onClick}>
                                    <div className='container-icon'>
                                        <FontAwesomeIcon icon={box.icon} className='icon' />
                                    </div>
                                    <h3>{box.title}</h3>
                                    <div className='container-img'>
                                        <img src={box.image} alt={box.title} />
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='box-right' onClick={openMenuDialog}>
                            <div className='container-icon'>
                                <FontAwesomeIcon icon={faUtensils} className='icon' />
                            </div>
                            <h3>Menu du jour</h3>
                            <p>Découvrez nos spécialités fraîches préparées chaque jour.</p>
                        </div>
                    </>
                )}
            </div>
            <div ref={contactRef}> 
                <Contact />
            </div>
            {menuDialogOpen && <Menu closeMenu={closeMenuDialog} />}
        </div>
    );
}

function debounce(func, wait) {
    let timeout;
    return function(...args) {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), wait);
    };
}

export default Home;
