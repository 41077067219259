import React, { useState, useEffect, useRef } from 'react';
import './Header.css'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';
import Menu from '../Menu/Menu'; 

function Header({ onContactClick }) { 
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuDialogOpen, setMenuDialogOpen] = useState(false); 
    const navRef = useRef();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const openMenuDialog = () => {
        setMenuDialogOpen(true); 
        setMenuOpen(false); 
    };

    const closeMenuDialog = () => {
        setMenuDialogOpen(false); 
    };

    const openBanquetsInNewTab = () => {
        const newWindow = window.open('https://www.foodbooking.com/ordering/?restaurant_uid=192ba7bc-da1a-47c8-a944-ca83c35711fc&site_url=aHR0cHM6Ly93d3cuYWxleG90aXF1ZS5jaC8%3D', '_blank');
        if (!newWindow) {
            alert("Veuillez autoriser les pop-ups pour cette action.");
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navRef.current && !navRef.current.contains(event.target)) {
                setMenuOpen(false);
            }
        };

        if (menuOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [menuOpen]);

    return (
        <header>
            <ul>
                <FontAwesomeIcon className='open-menu' icon={faBars} onClick={toggleMenu} />
                <div ref={navRef} className={`container-nav ${menuOpen ? 'open' : ''}`}>
                    <div className='container-close'>
                        <FontAwesomeIcon className='close-menu' icon={faXmark} onClick={toggleMenu} />
                    </div>
                    <li><span>Accueil</span></li>
                    <li><span onClick={openMenuDialog}>Menu du jour</span></li> 
                    <div className='space'></div>
                    <li><span onClick={openBanquetsInNewTab}>Banquets</span></li>
                    <li><span onClick={onContactClick}>Contact</span></li> 
                </div>
            </ul>
            {menuDialogOpen && <Menu closeMenu={closeMenuDialog} />}  
        </header>
    );
}

export default Header;
